'use strict'
{appStore, authStore, patientStore, chargesStore} = require 'stores'

synopsisStore = require('stores/new-synopsis')
newAuthStore = require('stores/new-auth')
scheduleStore = require('stores/schedule').default
{observer} = require('mobx-react')
{autorun} = require('mobx')
PaymentCard = require './PaymentCard'
DeleteChargeModal = require './DeleteChargeModal'
AddChargeModal = require './AddChargeModal'
{ProvidersProfile, showProvidersProfiles} = require('components/appointments/ProvidersProfile')
Footer = require 'components/app/Footer'
ProfileHeader = require('./profile-header')
PCPInfoPopUp = require('./PCPInfoPopUp')
SOGIInfoPopUp = require('./SOGIInfoPopUp')
Select = require('components/elements/new-select').default
ConfirmationModal = require 'components/elements/ConfirmationModal'
ActionDropdown = require 'components/elements/ActionDropdown'
Progress = require 'components/elements/Progress'
showVerifyPatientModal = require 'components/elements/VerifyPatientModal'
Main = require('components/elements/main')
ContentHeader = require('components/elements/content-header')
withMediaQueries = require('components/elements/with-media-queries')
RaceEthnicityPopUp = require('./RaceEthnicityPopUp')
{showModal} = require('components/elements/new-modal')
{smDown, mdDown} = require('lib/media-queries')
{Link, withRouter} = require('react-router')
{unlockAccount} = require('./helpers')
{currency, utils} = require('lib/utils')
{client} = require 'config'

toPrimaryPlan = (p) =>
  return {
    name: p.field1.value,
    holder: p.field2.value,
    id: p.field3.value,
  }

getPrimaryValue = (plan, key) =>
  value = plan[key]
  if key == 'holder' && ['Spouse', 'Child', 'Partner'].includes(value)
    return 'Dependent'

  return value || 'No Data'

{startCase, flatten, isEmpty, map, omit, some, isEqual} = require 'lodash'
langs = require 'langs'
utils = require 'lib/utils'
config = require 'config'
{cx} = Exim.helpers

{ul, li, article, fa, div, main, header, section, h1, h2, h3, h4, h5, strong, small, button, span, img, a, br, p, i, dl, dt, dd, p, select, option, optgroup, table, tbody, thead, tr, td, th} = Exim.DOM

phone = (label, number, extra) ->
  link = if number
    span className: 'phone-number', "#{ number }#{if extra then " " else ''}"
  else
    'None'

  li null,
    "#{label}: "
    link
    extra

DECLINE = 'dr-declined'
COMMUNITY = 'dr-community-provider'
MISMATCH = 'dr-ehr-mismatch'

ShowProfile = React.createClass({
  displayName: 'ShowProfile',
  mixins: [
    authStore.connect('user', 'impersonatingUser', 'oAuthProviderLinks'),
    patientStore.connect('patient', 'pcps', 'unpaidCharges', 'updatingCoverage'),
    chargesStore.connect('cards', 'updatingCard'),
  ],

  dataName: 'patient'

  getInitialState: ->
    this.planRef = React.createRef()
    this.pcpRef = React.createRef()

    return {
      hasPCP: false,
      tab: 'profile',
      coverageStatus: null,
      isEditingPCP: false,
      declineReason: '',
      isEditingInsurance: false,
      isProfilesShown: false
    }

  getInitialData: ->
    @state.patient

  componentWillMount: ->
    appStore.actions.setHeader(null)
    if !authStore.get('impersonatingUser')
      patientStore.actions.fetch()
      patientStore.actions.fetchPCPs()

    user = authStore.get('user')
    chargesStore.actions.fetchCards(user.id)
    patientStore.actions.fetchUnpaidCharges(user.id) if user.admin or user.proxying
    scheduleStore.fetchProvidersProfiles()
    scheduleStore.setIsProfilePage(true)
    this.setState({ hasPCP: !(user.xo_physician_global_id == DECLINE || user.xo_physician_global_id == COMMUNITY || user.xo_physician_global_id == MISMATCH) })
    this.pcpsProviderProfiles()

    this.disposePlansAutorun = autorun(() =>
      if newAuthStore.isProxying
        synopsisStore.fetchPlans()
    )

  componentWillUpdate: (_, nextState)->
    userWasChanged = @state.user?.id isnt nextState.user?.id
    if userWasChanged
      chargesStore.actions.fetchCards(nextState.user?.id)
      patientStore.actions.fetchUnpaidCharges(nextState.user?.id) if nextState.user?.admin or nextState.user?.proxying
      this.closeProfilesModal()
      this.pcpsProviderProfiles()

  componentDidMount: ->
    appStore.actions.setTitle('Profile')

  componentDidUpdate: (prevProps, prevState) ->
    if (isEqual(this.state.pcps, prevState.pcps) == false && !isEmpty(this.state.pcps))
      this.pcpsProviderProfiles()

  componentWillUnmount: ->
    appStore.actions.setTitle()
    this.disposePlansAutorun()

  invite: ->
    {user} = @state
    action = => patientStore.actions.sendInvite().then =>
      @closeModal()
    @confirmationModal('Invite patient?',
                       "#{user.full_name} will receive an invitation email.",
                       'Send invite',
                       action)
  verify: ->
    action = => patientStore.actions.sendVerification().then =>
      @closeModal()
    @confirmationModal("Verify patient's account?",
                       'Account will be verified',
                       'Verify account',
                       action)
  reset: ->
    {user} = @state
    action = => patientStore.actions.resetPassword().then =>
      @closeModal()
    @confirmationModal("Reset patient's password?",
                       "#{user.full_name} will receive a password reset email.",
                       'Reset password',
                       action,
                       'small',
                       'Password reset request sent',
                       'action-dropdown-show-additional-options')

  reactivate: ->
    {user, patient} = @state
    if patient.email
      action = => patientStore.actions.reactivate().then =>
        authStore.actions.fetchUser()
        @closeModal()
      @confirmationModal('Reactivate this patient?',
                         "#{user.full_name} will be able to access #{if user.gender is 'M' then 'his' else 'her'} account.",
                         'Reactivate patient',
                         action)
    else
      this.props.router.push pathname: '/profile/edit', query: focusEmail: true

  inactivate: ->
    {user} = @state
    action = => patientStore.actions.inactivate().then =>
      authStore.actions.fetchUser()
      @closeModal()
    @confirmationModal('Deactivate this patient?',
                       "#{user.full_name} will no longer be able to access #{if user.gender is 'M' then 'his' else 'her'} account.",
                       'Deactivate patient',
                       action)

  whatsNew: ->
    this.props.router.push pathname: '/whatsNew'

  oauthLink: (provider) -> (e) =>
    @confirmationModal(
      "Link to #{provider.label}",
      [
        { text: [
          p key: 'oauth-confirm-question', "Do you want to link your portal account to your #{provider.label} account?"
          p key: 'oauth-confirm-meaning', 'What does this mean?'
        ]},
        { text: "Access to your Portal account will use your #{provider.label} credentials" },
        { text: 'Your current Portal password will be removed' },
        { text: "Your medical information is still securely stored in the Portal. Your #{provider.label} ID is used solely by you to gain entry to your account." }
      ],
      (if provider.icon_name
        [ (fa provider.icon_name), (span "Link to #{provider.label}") ]
      else
        "Link to #{provider.label}"),
      () ->
        window.location = utils.expandUriTemplate(provider.href, { next: 'link_account' })
      'medium'
    )

  renderActions: ->
    user = authStore.get('user')
    {user: newUser, me, isPortalReadOnlyForMember} = newAuthStore

    if isPortalReadOnlyForMember
      return null
    userActions = switch user.status
      when 'Unregistered' then [{handler: @invite, name: 'Send Invite'}]
      when 'Pending'      then [{handler: @verify, name: 'Resend Verification'}]
      when 'Active'       then (if user.oauth_sign_in then [] else [{handler: @reset, name: 'Reset Password'}])
      else []

    userActions.push({handler: @verifyPatientModal, name: 'Verify Account'}) if user.restricted_dependent and !user.admin and user.proxying
    userActions.push({handler: @inactivate, name: 'Inactivate Account'}) if user.status in ['Active', 'Pending', 'Unregistered'] and (!user.admin and user.proxying)
    userActions.push({handler: @reactivate, name: 'Reactivate Account'}) if user.status is 'Inactive' and (!user.admin and user.proxying)

    {user: newUser, me} = newAuthStore
    userActions.push({handler: @reactivate, name: 'Reactivate Account'}) if newUser.status is 'Ineligible' and (!newUser.isAdmin and newUser.isProxying) and newUser.canReactivateIneligibles
    userActions.push({handler: unlockAccount, name: 'Unlock Account'}) if me.isAdmin and newUser.isProxying
    userActions.push({handler: @whatsNew, name: "What's New"})

    if !user.oauth_sign_in
      (this.state.oAuthProviderLinks || []).filter((p) => (user.oauth_links || []).includes(p.key)).forEach((p) =>
        userActions.push({handler: @oauthLink(p), name: "Link to #{p.label}"})
      )

    userActions.unshift({handler: @edit, name: 'Edit Account'})

    return ActionDropdown
      className: 'u-flexChild ActionDropdown--xlarge ActionDropdown--titleInner ActionDropdown--white-bg'
      values: userActions
      key: 'dropdown'
      withGlobalClick: true
      needsTwoTabs: true

  closeModal: ->
    appStore.actions.showModal(null)

  confirmationModal: (title, text, confirmText, onConfirm, size = 'small', srOnlyText = '', activatorElId) ->
    appStore.actions.showModal({
      className: "Modal--#{size}",
      contentLabel: title,
      darker: true,
      body: ConfirmationModal({title, text, confirmText, onCancel: @closeModal, onConfirm, srOnlyText, activatorElId}),
    })

  edit: ->
    this.props.router.push pathname: '/profile/edit'

  toggleTextUpdates: (evt) ->
    evt.preventDefault()
    status = !@state.patient.cell_texts
    patientStore.actions.toggleTextUpdates(status)

  togglePCPEdit: ->
    this.setState({
      isEditingPCP: !this.state.isEditingPCP,
      isProfilesShown: false,
    })

  toggleInsuranceEdit: ->
    this.setState({
      isEditingInsurance: !this.state.isEditingInsurance,
    })

  toggleProfilesModal: ->
    this.setState({isProfilesShown: !this.state.isProfilesShown})

  closeProfilesModal: (e) ->
    this.setState({isProfilesShown: false})

  renderProvidersProfiles: ->
    return ProvidersProfile({
      closeModal: this.closeProfilesModal,
      scheduleStore: scheduleStore,
      profilePageProfiles: scheduleStore.pcpProviderProfiles,
      renderScheduler: false,
      className: 'profiles-profile-page'
    })

  renderPCPOnClick: ->
    return showProvidersProfiles({
      isPhone: this.props.isPhone,
      isZoom: this.props.isZoom,
      renderProvidersProfiles: this.renderProvidersProfiles,
      toggleModal: this.toggleProfilesModal
    })

  pcpsProviderProfiles: ->
    { pcps } = this.state
    { name } = newAuthStore.user.defaultSite

    arr2 = []
    uniqProfilesArray = []

    pcps[name] && pcps[name].map (arr1) ->
      return arr2.push(arr1)

    uniqArr = Array.from(new Set(arr2))

    if (!uniqArr.length > 0)
      scheduleStore.providersProfiles.map (profile) ->
        if (!uniqProfilesArray.some (prof) -> (prof.globalId == profile.globalId))
          uniqProfilesArray.push(profile)
    else
      scheduleStore.providersProfiles.filter (profile) ->
        return uniqArr.forEach (el) ->
          if (el.global_id == profile.globalId && !uniqProfilesArray.some (prof) -> (prof.globalId == profile.globalId))
            uniqProfilesArray.push(profile)

    scheduleStore.setPcpProviderProfiles(uniqProfilesArray)

  sogiInfoIconClick: (evt) ->
    {hasRaceAndEthnicity, featureFlagsLoaded, user, isProxying} = newAuthStore
    showRaceAndEthnicity = featureFlagsLoaded && hasRaceAndEthnicity && user.isAdmin
    evt.stopPropagation()
    showModal(React.createElement(SOGIInfoPopUp, rect: evt.target.getBoundingClientRect(), isCtm: (user.isAdmin or  user.isProxying), hasRaceAndEthnicity: showRaceAndEthnicity, showSexualOrientation: false, hideNameUsed: isProxying))

  raceEthnicityIconClick: (evt) ->
    evt.stopPropagation()
    showModal(React.createElement(RaceEthnicityPopUp, rect: evt.target.getBoundingClientRect()))

  pcpInfoIconClick: (evt) ->
    evt.stopPropagation()
    showModal(React.createElement(PCPInfoPopUp, rect: evt.target.getBoundingClientRect()))

  renderPCP: ->
    {me, user, isPortalReadOnlyForMember} = newAuthStore
    return if user.isAdmin

    {isEditingPCP, declineReason} = this.state

    return section className: 'ProfileView-content-block-section ProfileView-content-block--pcp-section',
      div null,
        h4 id:'primaryprovider', if client != 'apple' && client != 'cork' then 'Primary Crossover Provider ' else 'Primary Provider '
        span null,
          i className: 'fa fa-info-circle ProfileView-pcp-info-icon', id: 'profile-view-info-icon', role: 'button', 'aria-label': 'What is a Primary Crossover Provider?', tabIndex: '0', onClick: this.pcpInfoIconClick, onKeyPress: utils.a11yClick(this.pcpInfoIconClick),
        if client != 'apple' && client != 'cork' && client != 'microsoft' && scheduleStore.pcpProviderProfiles.length
          div className: 'ProfileView-prov-profile-wrap',
            button id: 'provider-bios-modal-activator', className: 'Button Button--provider-bios', onClick: this.renderPCPOnClick, 'View Provider Bios', i className: 'fa fa-list-alt ProfileView-provs-profile-btn-white-icon'
        if this.state.isProfilesShown
          this.renderProvidersProfiles()
      if isEditingPCP
        div style: {display: 'flex', flexWrap: 'wrap'},
          div role: 'radiogroup',
            React.createElement(React.Fragment, null,
              div style: {display: 'flex'},
                button {
                  'aria-label': "Enable",
                  role: 'radio',
                  style: {width: '90px'},
                  onClick: () => this.setState({ hasPCP: true }),
                  'aria-checked': "#{if this.state.hasPCP then true else false}",
                },
                  div className: "Radio #{this.state.hasPCP && 'selected'}"
                  span style: {margin: '0 10px'}, 'Enable'
                button {
                  'aria-label': "Decline",
                  role: 'radio',
                  'aria-checked': "#{if !this.state.hasPCP then true else false}",
                  style: {width: '90px'},
                  onClick: () => this.setState({ hasPCP: false })
                },
                  div className: "Radio #{!this.state.hasPCP && 'selected'}"
                  span style: {margin: '0 10px'}, 'Decline'
                this.state.hasPCP &&
                  React.createElement(Select, {
                    id: 'profile-pcp-select',
                    'aria-labelledby': 'select-pcp-placeholder',
                    className: 'ProfileView-select',
                    defaultValue: if (synopsisStore.globalIdAsPCP) then user.xo_physician_global_id else user.pcp,
                    ref: this.pcpRef,
                  }, this.renderPCPOptions())
                !this.state.hasPCP &&
                  React.createElement(Select, {
                    id: 'profile-decline-select',
                    className: 'ProfileView-select',
                    'aria-label': 'Select decline options',
                    defaultValue: user.xo_physician_global_id || '',
                    onChange: this.setValue,
                    ref: this.pcpRef,
                  }, this.renderDeclineOptions())
                button id: 'profile-pcp-save', className: 'ProfileView-save', disabled: (!this.state.hasPCP && !declineReason), onClick: this.savePCP,
                  'Save'
            )
      else
          displayName = this.pcpDisplayName(user)
          ul 'aria-labelledby': 'primaryprovider',
          li id: 'profile-pcp-value', className: 'ProfileView-pcp-displayName',
            if user.xo_physician_global_id == COMMUNITY
              'I Already have a Community PCP'
            else if user.xo_physician_global_id == DECLINE
              'I Decline Crossover Provider'
            else if user.xo_physician_global_id == MISMATCH
              "#{if me.isAdmin then'EHR Mismatch' else 'None'}" 
            else
              displayName || 'None'
            !isPortalReadOnlyForMember &&
              button id: 'profile-pcp-toggle', className: 'Button Button--profile', onClick: this.togglePCPEdit,
                "#{if isEditingPCP then 'Cancel' else 'Edit'}"

  pcpDisplayName: (user) ->
    if (synopsisStore.globalIdAsPCP || user.xo_physician_global_id != undefined)
      return this.getPCPName(user.xo_physician_global_id)
    else
      return user.pcp

  getPCPName: (globalId) ->
    {user} = newAuthStore
    providers = [];
    displayName = ''
    Object.entries(this.state.pcps).forEach (site, index) ->
          providers.push(site[1])
    flatten(providers).forEach (provider) ->
        if(provider['global_id'] != null && provider['global_id'] == globalId)
          displayName = provider['name']
        else
          provider = synopsisStore.providers.find (prov) ->
            prov['globalId'] != null && prov['globalId'] == globalId
          if(provider)
            displayName = provider['name']
          else
            return null
    return displayName;

  setValue: (evt) ->
    this.setState({ declineReason: evt.currentTarget.value })

  savePCP: ->
    {user} = newAuthStore
    {value} = this.pcpRef.current

    if (!this.state.hasPCP && !value)
      return

    if(synopsisStore.globalIdAsPCP)
      user.update(xo_physician_global_id: value).then(() =>
        this.setState({isEditingPCP: false})
        )
    else
      user.update(pcp: value).then(() =>
        this.setState({isEditingPCP: false})
        )

  renderDeclineOptions: ->
    return React.createElement(React.Fragment, null,
      option value: '', 'Select Decline Options'
      option value: '', disabled: true, '---'
      option value: DECLINE, 'I Decline Crossover Provider'
      option value: COMMUNITY, 'I Already have a Community PCP'
    )

  renderPCPOptions: ->
    {user} = newAuthStore

    providers = Object.entries(this.state.pcps)
      .map ([site, providers]) ->
        if site == user.defaultSite.name
          if user.is_kaiser_restricted
            filteredPcpProvs = providers
          else 
            filteredPcpProvs = providers.filter ((prov) -> prov.pcp_eligible)
          optgroup key: site, label: site,
            filteredPcpProvs
              .map (prov) ->
                option key: prov['name'], value: (if(synopsisStore.globalIdAsPCP) then prov['global_id'] else prov['name']),
                  prov['name']

    return React.createElement(React.Fragment, null,
      option id: 'select-pcp-placeholder', value: '', 'Select Primary Provider'
      option value: '', disabled: true, '---'
      providers
    )

  renderInsuranceOptions: ->
    return map(synopsisStore.plans, (plans, site) =>
      return optgroup key: site, label: site,
        plans.map((plan) =>
          return option key: plan, value: plan,
            plan.split(':')[1]
        )
    )

  hasRecentlyRefreshed: ->
    {patient} = this.state
    # Check the existence of this timestamp to prevent moment formatting warnings
    if (patient.insurance?.updated?)
      return moment(patient.insurance.updated).add(1, 'day') <= moment()
    else
      return false

  refreshInsuranceInfo: ->
    {patient} = this.state
    patientStore.actions.updateCoverage(patient.id).then (data) =>
      @setState {coverageStatus: data[0]?.status}

  saveInsurance: ->
    {user} = newAuthStore
    {value} = this.planRef.current

    user.update({medicalPlan: value}).then(() =>
      this.setState({isEditingInsurance: false})
    )

  verifyPatientModal: ->
    showVerifyPatientModal(@state.patient.email)
      .then => authStore.actions.fetchUser()
      .catch ->

  renderPrimaryContact: (contactType) ->
    {patient} = this.state
    if contactType is patient.primary_contact
      return span className: 'ProfileView-primaryContact', title: 'Preferred Contact',
        fa 'check-circle'

  showDeleteChargeModal: (chargeId) -> (e) =>
    appStore.actions.showModal({
      className: 'DeleteChargeModal',
      title: 'Delete Charge',
      body: DeleteChargeModal({
        close: @chargeModalCallback,
        chargeId,
      }),
      noClose: true,
    })

  showAddChargeModal: ->
    {user} = newAuthStore

    appStore.actions.showModal({
      className: 'AddChargeModal',
      title: 'Add Unpaid Item',
      body: AddChargeModal({
        close: @chargeModalCallback,
        userId: user.id,
      }),
      noClose: true,
    })

  chargeModalCallback: ->
    patientStore.actions.fetchUnpaidCharges(authStore.get('user')?.id).then =>
      @closeModal()

  isPaymentsDisabled: ->
    user = this.state.impersonatingUser or this.state.user
    {me, isProxying} = newAuthStore
    isApple = ['crk', 'scv', 'prk', 'wfe'].includes(user.default_site)
    config.features.appleDisablePayments && isApple or (me.isAdmin and !isProxying)

  renderProfileTab: ->
    {patient} = this.state
    {isPortalReadOnlyForMember,hasRaceAndEthnicity, featureFlagsLoaded, isProxying, user } = newAuthStore
    showRaceAndEthnicity = featureFlagsLoaded && hasRaceAndEthnicity
    raceMapping = {
      american_indian:'American/Indian or Alaskan native',
      asian: 'Asian',
      black: 'Black or African American',
      native_pacific: 'Native Hawaiian or Other Pacific Islander',
      white: 'White',
      prefer_not_to_say: 'Prefer not to say'
    }

    ethnicityMapping = {
      hispanic: 'Hispanic or Latino',
      not_hispanic: 'Not Hispanic or Latino',
      prefer_not_to_say: 'Prefer not to say'
    }

    return React.createElement(React.Fragment, null,
      article className: 'ProfileView-content-block',
        h3 'Personal Information'

        section className: 'ProfileView-content-block-section',
          h4 id: 'name', 'Legal Name'
          ul 'aria-labelledby': 'name',
            li patient.legal_and_preferred_name,

        section className: 'ProfileView-content-block-section',
          h4 id: 'language', 'Preferred Language'
          ul 'aria-labelledby': 'language',
            li langs.where(2, patient.preferred_language)?.name || 'Unspecified'

        if showRaceAndEthnicity && !user.isAdmin
          section className: 'ProfileView-content-block-section',
            div id: 'race-ethnicity-header',
              h4 id: 'race', 'Race'
              span null,
                i className: 'fa fa-info-circle ProfileView-pcp-info-icon', id: 'race-ethnicity-info-icon', role: 'button', 'aria-label': 'Race and ethnicity personal info', tabIndex: '0', onClick: this.raceEthnicityIconClick, onKeyPress: utils.a11yClick(this.raceEthnicityIconClick),
            ul 'aria-labelledby': 'race',
              li raceMapping[patient.race] ||"Not answered"

        if showRaceAndEthnicity && !user.isAdmin
          section className: 'ProfileView-content-block-section',
            h4 id: 'ethnicity', 'Ethnicity'
            ul 'aria-labelledby': 'ethnicity',
              li ethnicityMapping[patient.ethnicity]||"Not answered"

        section className: 'ProfileView-content-block-section',
          h4 id:'address', 'Address'
          ul 'aria-labelledby': 'address',
            li patient.legal_name
            li patient.address
            li "#{ if patient.city then patient.city + ',' else '' } #{ patient.state or ''} #{ patient.zip or '' }"

        this.renderPCP()

        section className: 'ProfileView-content-block-section',
            h4 id: 'contactinfo', 'Contact information'
            ul 'aria-labelledby': 'contactinfo',
              phone 'Mobile', patient.cell_phone,
                React.createElement(React.Fragment, null,
                  @renderPrimaryContact('cell')
                  if patient.smss && patient.cell_phone && !isPortalReadOnlyForMember
                    button className: 'Button Button--profile', onClick: this.toggleTextUpdates,
                      "#{if patient.cell_texts then 'Disable' else 'Enable'} text reminders"
                )
              phone 'Home', patient.home_phone, @renderPrimaryContact('home')
              phone 'Work', patient.work_phone, @renderPrimaryContact('work')
              li 'Email: ',
                a id: 'profile-email', className: 'ProfileView-email underline', href: "mailto:#{patient.email}", patient.email
                @renderPrimaryContact('email'),

        if patient.other
          section className: 'ProfileView-content-block-section',
            h4 id:'other', 'Other'
            ul 'aria-labelledby': 'other',
            map(patient.other, (value, key) =>
              li key: key, "#{startCase(key)}: #{value || 'None'}"
            )

        section className: 'ProfileView-content-block-section ProfileView-content-block--emergency',
          h4 id: 'emergencycontact',
            fa 'medkit'
            'Emergency contact'
          ul 'aria-labelledby': 'emergencycontact',
            li patient.emergency_name or 'None'
            phone 'Phone', patient.emergency_phone
            li "Relationship: #{patient.emergency_relationship or 'None'}"

      article className: 'ProfileView-content-block',
        h3 'Insurance Information'
        this.renderInsuranceInfo()
        this.renderInsuranceAmounts()
    )

  getOurPlan: ->
    {user} = newAuthStore
    {patient} = this.state

    return {
      name: (user.medicalPlan || '').split(':')[1] || 'Self Pay',
      holder: patient.relationship || 'Self Pay',
      id: patient.medical_policy || 'Self Pay',
    }

  renderPrimaryInsurance: (patientPlan) ->
    ourPlan = this.getOurPlan()

    renderValue = (key) =>
      patientValue = getPrimaryValue(patientPlan, key)

      if newAuthStore.isProxying
        ourValue = getPrimaryValue(ourPlan, key)

        if patientValue != ourValue
          return "#{ourValue} (Patient said: #{patientValue})"

      return patientValue

    return div id: 'profile-plan-primary',
      div "Name: #{renderValue('name')}"
      div "Policyholder: #{renderValue('holder')}"
      div "ID Number: #{renderValue('id')}"

  renderInsuranceInfo: ->
    {user, isProxying} = newAuthStore
    {patient, isEditingInsurance} = this.state
    {insurances = {}} = patient
    ourPlan = this.getOurPlan()

    renderInsurance = (ins, insKey) =>
      return if ins.disabled

      return section key: insKey, className: 'ProfileView-insurance', 'data-ins': insKey,
        h5 id: insKey, ins.label || 'Insurance'
        ul 'aria-labelledby': insKey,
        if ins.label == 'Primary Insurance'
          this.renderPrimaryInsurance(toPrimaryPlan(ins))
        else
          map(omit(ins, ['label']), ({name, value}, key) =>
            return li key: key, "#{name}: #{value || 'No Data'}"
          )

    isRefreshDisabled = this.state.updatingCoverage || this.hasRecentlyRefreshed()
    isPrimaryFirst = (insurances.insurance_1 || {}).label == 'Primary Insurance'
    hasProxyingInfo = isProxying && !isPrimaryFirst

    return section className: 'ProfileView-content-block-section',
      h4 'Plan Details '
        if isProxying
          if synopsisStore.isFetchingPlans
            Progress()
          else
            div className: 'ProfileView-buttonWrapper',
              '('
              button id: 'profile-plan-toggle', className: 'ProfileView-button', onClick: this.toggleInsuranceEdit,
                if isEditingInsurance then 'cancel' else 'edit'
              ' / '
              button className: 'ProfileView-button', disabled: isRefreshDisabled, onClick: this.refreshInsuranceInfo,
                'refresh'
              ')'
      if isProxying && isEditingInsurance
        dd className: 'ProfileView-content-dropdown',
          React.createElement(Select, {
            id: 'profile-plan-select',
            className: 'ProfileView-select',
            defaultValue: user.medicalPlan,
            ref: this.planRef,
          }, this.renderInsuranceOptions())
          button id: 'profile-plan-save', className: 'ProfileView-save', onClick: this.saveInsurance,
            'Save'
      if isEmpty(insurances)
        dd className: 'ProfileView-insurance',
          # TODO: use `User` model here
          this.renderPrimaryInsurance({
            name: patient.insurance_plan,
            holder: patient.insurance_holder,
            id: patient.insurance_id,
          })
      else
        React.createElement(React.Fragment, null,
          if hasProxyingInfo
            section className: 'ProfileView-section',
              h4 id: 'eligibilityinfo', 'Eligibility Info'
              ul 'aria-labelledby': 'eligibilityinfo',
                li "Plan Status: #{ourPlan.name}"
                li "Insurance ID: #{patient.insurance_id}" if patient.insurance_id
          section className: 'ProfileView-section',
            h4 'Patient-Entered Info' if hasProxyingInfo
            map(insurances, renderInsurance)
        )

  renderInsuranceAmounts: ->
    ourPlan = this.getOurPlan()
    return if ourPlan.id == 'Self Pay'

    {user} = newAuthStore
    {patient} = this.state
    ins = patient.insurance || {}
    hraBalance = patient.hra_balance

    noInsuranceData = !('iml' of ins)
    showFamily = 'fml' of ins
    showIndividual = !('coi' of ins && showFamily)

    if ins.cop
      officeVisit = "#{currency(ins.cop)} copay"
    else if ins.coi
      coi = parseFloat(ins.coi) * 100
      officeVisit = "#{currency(coi)}% coinsurance"

    youSpent = if !showIndividual && +ins.ids then currency(ins.ids) else null

    return div className: 'ProfileView-content-block-section--insurance-balance',
      if showIndividual
        div null,
          dl className: 'ProfileView-content-block-section',
            dt "Individual Deductible (#{currency(ins.idl)})"
            if noInsuranceData
              dd 'No Data'
            else
              dd null,
                span "#{currency(ins.ids)} spent"
                br null
                span "#{currency(ins.idr)} remaining"
          dl className: 'ProfileView-content-block-section',
            dt "Individual Maximum (#{currency(ins.iml)})"
            if noInsuranceData
              dd 'No Data'
            else
              dd null,
                span "#{currency(ins.ims)} spent"
                br null
                span "#{currency(ins.imr)} remaining"
      if showFamily
        target = if user.medicalPlan == 'Premera HSA' then 'Plan' else 'Family'

        div null,
          dl className: 'ProfileView-content-block-section',
            dt "#{target} Deductible (#{currency(ins.fdl)})"
            if youSpent
              dd null,
                span "#{currency(ins.fds)} spent (#{youSpent} by you)"
                br null
                span "#{currency(ins.fdr)} remaining"
            else
              dd null,
                span "#{currency(ins.fds)} spent"
                br null
                span "#{currency(ins.fdr)} remaining"
          dl className: 'ProfileView-content-block-section',
            dt "#{target} Maximum (#{currency(ins.fml)})"
            dd null,
              span "#{currency(ins.fms)} spent"
              br null
              span "#{currency(ins.fmr)} remaining"
      if officeVisit
        dl className: 'ProfileView-content-block-section',
          dt 'Office Visit'
          dd officeVisit
      if hraBalance
        dl className: 'ProfileView-content-block-section',
          dt 'HRA Balance'
          dd "#{currency(hraBalance)} remaining"

      if ins.updated
        div className: 'ProfileView-content-block-section',
          small if this.state.coverageStatus == 202 then 'Update in progress' else "Updated #{utils.formatDateNicely(ins.updated)}"

  renderPaymentTab: ->
    user = this.state.impersonatingUser or this.state.user
    {token} = appStore.get('config')
    {cards} = this.state

    return React.createElement(React.Fragment, null,
      if user.admin or user.proxying
        div className: 'ProfileView-content-block PaymentHistory',
          h3 'Unpaid Items'
          table className: 'NewTable',
            thead {},
              tr {},
                th style: {width: '10%'}, 'Owed'
                th style: {width: '20%'}, 'Item'
                th style: {width: '45%'}, 'Description'
                th style: {width: '15%'}, 'Service Date'
                th style: {width: '10%', textAlign: 'center'}, 'Delete'
            tbody {},
              @state.unpaidCharges?.map (charge) =>
                tr {},
                  td "$#{(charge.amount/100).toFixed(2)}"
                  td charge.item
                  td charge.description
                  td moment(charge.charge_date).format('ll')
                  if user.admin or user.proxying
                    td style: {textAlign: 'center'},
                      span onClick: @showDeleteChargeModal(charge.id),
                        fa 'times fa-lg'

          div className: 'PaymentHistory-addCharge', onClick: @showAddChargeModal,
            '+ Add New Unpaid Item'

      if token and !@isPaymentsDisabled()
        div className: "ProfileView-content-block PaymentCards #{cx 'CardsUpdating': @state.updatingCard}",
          h3 'Payment Information'
          (cards || []).map((card) => React.createElement(PaymentCard, card))
          React.createElement(Link, {
            className: 'PaymentCard PaymentCard--add',
            to: '/profile/payment',
            role: 'button',
          },
            div className: 'PaymentCardAddMethod',
              fa 'plus-circle'
              'Add payment method'
          )
    )

  render: ->
    {tab} = this.state
    user = this.state.impersonatingUser or this.state.user
    {isPortalReadOnlyForMember} = newAuthStore

    # Prevent re-rendering before signout
    return div() if !user.id
    return React.createElement(React.Fragment, null,
      React.createElement(ContentHeader, {title: 'Account'}, this.renderActions())
      React.createElement(Main, {className: "Content #{cx 'Content--withPatientHeader': @state.user.proxying}"},
        section className: 'Content-body no-padding',
          div className: 'ContentInner ProfileView',
            React.createElement(ProfileHeader, {onClick: @sogiInfoIconClick, onClose: @closeModal})

            unless this.isPaymentsDisabled()
              div role: 'tablist', id: 'profile-view-toggles', className: 'ProfileView-view-toggle',
                div {
                  className: "ProfileView-view-toggle-opt #{cx 'is-selected': tab == 'profile', 'full-width': isPortalReadOnlyForMember}",
                  'aria-selected': "#{if tab == 'profile' then true else false}",
                  role: 'tab',
                  tabIndex: 0,
                  onClick: () =>
                    this.setState({tab: 'profile'})
                  onKeyPress: utils.a11yClick(() =>
                    this.setState({tab: 'profile'}))
                }, 'Profile'
                if !isPortalReadOnlyForMember
                  div {
                    className: "ProfileView-view-toggle-opt #{cx 'is-selected': tab == 'payment'}",
                    'aria-selected': "#{if tab == 'payment' then true else false}",
                    'data-e2e': 'view-payment-tab',
                    role: 'tab',
                    tabIndex: 0,
                    onClick: () =>
                      this.setState({tab: 'payment'})
                    onKeyPress: utils.a11yClick(() =>
                      this.setState({tab: 'payment'})),
                  }, 'Payment'

            div className: 'ProfileView-content',
              if (tab == 'profile' || this.isPaymentsDisabled()) then this.renderProfileTab() else this.renderPaymentTab()

            span className: 'BuildTime', 'Build Time: ' + config.buildDate if user.admin or user.proxying
          Footer()
      )
    )
})

module.exports = withMediaQueries(withRouter(observer(ShowProfile)), {
  isPhone: smDown,
  isZoom: mdDown,
})
