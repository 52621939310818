{authStore, appStore} = require 'stores'

Tooltip      = require 'components/elements/Tooltip'
Validate     = require 'components/mixins/Validate'
utils        = require 'lib/utils'
{production, isCordova} = require 'config'

SLIDE_DURATION = 400
DELAY = 100
AUTOFOCUS = false

{cx} = Exim.helpers
{div, span, p, form, input, button, a, tch, label, fa, img, strong} = Exim.DOM

SignInForm = Exim.createView module.id,
  mixins: [Validate]

  listen: [
    'auth/signingIn'
    'auth/error'
    'auth/loggedIn'
    'auth/canFingerprintAuth'
    'auth/storedAuth'
    'auth/clearingError'
    'auth/oAuthProviderLinks'
  ]

  contextTypes:
    router: React.PropTypes.object.isRequired

  getInitialState: ->
    prod = production
    tooltip: {}
    email: ''
    password: ''

  componentWillMount: ->
    if @props.location?.query?.error
      authStore.actions.loadInitialError(@props.location?.query?.error)
    else
      authStore.actions.clearError()

    Promise.all([
      authStore.actions.checkFingerprintAuth()
      authStore.actions.checkStoredCreds()
    ]).then(() =>
      canSignFingerprintAuth = @state.canFingerprintAuth and @state.storedAuth
      if canSignFingerprintAuth
        @signinFingerprintAuth()
    )

  componentDidMount: ->
    if AUTOFOCUS
      fn = => @refs.email.focus()
      setTimeout fn, SLIDE_DURATION + DELAY

  forceErrorState: (is_errorous) ->
    for inp in ['email', 'password']
      document.querySelector( '#labeled-input-' + inp )?.classList[if is_errorous then 'add' else 'remove']('error')
    return

  componentDidUpdate: (prevProps, prevState) ->
    if @state.clearingError
      return
    else if (prevState.clearingError isnt @state.clearingError) and !@state.clearingError
      @forceErrorState false
    else if @state.error
      @forceErrorState true
    return

  signinFingerprintAuth: (evt) ->
    evt?.preventDefault()
    authStore.actions.signinFingerprintAuth()

  formSubmit: (evt) ->
    evt.preventDefault()

    return if @state.signingIn

    reset = @state.error and /expired/i.exec(@state.error)
    {email, password} = @state

    if reset
      @sendReset(email)
    else
      @signIn(email, password)

  sendReset: (email) ->
    authStore.actions.forgot(email).then =>
      @context.router.push pathname: '/forgot', query: { sent: true }

  signIn: (email, password) ->
    if 'email' in @state.errors
      document.getElementById('labeled-input-email')?.focus()
    else if 'password' in @state.errors
      document.getElementById('labeled-input-password')?.focus()
    else if @state.error is 'Oops. Invalid email/password combination.'
      document.getElementById('labeled-input-email')?.focus()
    else
      document.getElementById('labeled-input-email')?.focus()
    
    @forceErrorState false
    saveTouch = @refs.saveTouch?.checked
    return unless email and password
    authStore.actions.clearError().then ->
      authStore.actions.signin({email, password}, saveTouch)

  oAuthSignIn: (uri) -> (evt) =>
    return unless isCordova

    evt.preventDefault()
    utils.openOAuthProvider(uri)

  showWhichSignInTypeModal: ->
    body = require './WhichSignInTypeModal'

    modal =
      className: 'Modal--medium'
      body: body()
      noClose: true

    appStore.actions.showModal(modal)

  goToPasswordReset: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/forgot'

  goToSignup: (evt) ->
    evt.stopPropagation()
    evt.preventDefault()
    @context.router.push pathname: '/signup'

  render: ->
    {signingIn, canFingerprintAuth, storedAuth} = @state
    canSignFingerprintAuth = canFingerprintAuth and storedAuth
    touchIDButton = if canSignFingerprintAuth then p(className: 'Input-TouchID-icon', onClick: @signinFingerprintAuth) else null
    platform = window.device?.platform?.toLowerCase()
    credentialsAreEmpty = @state.password == '' || @state.email == ''

    templateParams =
      next: 'signin'

    if platform
      templateParams.platform = platform

    div className: "SignInForm #{cx 'SignInForm--withTouchID': canFingerprintAuth}",
      if @state.oAuthProviderLinks?.length
        tch tagName: 'a', href: '#', className: 'Button--pointer Auth--informational-link underline', style: {textAlign: 'center', textDecoration: 'none'}, handler: @showWhichSignInTypeModal, 'Which Sign In is for me?'

      form ref: 'form', onSubmit: @formSubmit, autoComplete: 'off',
        input name: 'fakeemail', type: 'email', style: {display:'none'}
        input name: 'fakepassword', type: 'password', style: {display:'none'}

        div className: 'enter-creds',
          strong style: {marginBottom: '12px'}, 'Sign in with your Portal Credentials'
        @labeledInput 'email', 'Email Address', type: 'email', requiredField: true, error: 'Email address is a required field'
        @labeledInput 'password', 'Password', html: {classes: "#{cx 'Input--touchID-iconed': canSignFingerprintAuth}"}, type: 'password', extra: touchIDButton, requiredField: true, error: 'Password is a required field'

        if canFingerprintAuth
          label style: { fontSize: '14px', paddingBottom: '5px', marginTop: '-5px' },
            input type: 'checkbox', ref: 'saveTouch'
            if platform is 'ios'
              span ' Remember for Touch ID'
            else
              span ' Remember for Fingerprint Auth'

        tch tagName: 'a', href: '#', className: 'Button--pointer Auth--informational-link Auth--forgot-password-link', handler: @goToPasswordReset, 'Forgot your password?'

        if !@state.clearingError and @state.error
          span className: 'error',
            if @state.error isnt 'finger'
              @state.error
            else if @state.error is 'finger'
              'Invalid fingerprint.'
        span className: 'invisible error', tabIndex: -1, role: 'alert',
          if !@state.error
            ''
          else if @state.error isnt 'finger'
            @state.error
          else if @state.error is 'finger'
            'Invalid fingerprint.'

        tch tagName: 'button', className: "Button Button--primary Button--large Button-extend Button--auth #{cx 'is-disabled': signingIn || credentialsAreEmpty}", type: 'submit', disabled: signingIn || credentialsAreEmpty, handler: @formSubmit, (if @state.error and /expired/i.exec(@state.error) then 'Reset Password' else 'Sign In')

        if @state.oAuthProviderLinks?.length
          [
            span className: 'ruled-span', style: {color: '#737373'}, 'OR'
            span className: 'enter-creds', 'Sign In With Your Employer Credentials'
            div class: 'OAuth--reassurances',
              p "It's quick, easy, and secure."
              p "Your data will remain completely private."
            div className: 'OAuth--section',
              div className: 'OAuth--section--links',
                @state.oAuthProviderLinks?.map (prov) =>
                  tch tagName: 'a', key: prov.key, href: utils.expandUriTemplate(prov.href, templateParams), className: utils.cx('OAuthLink', {alt: prov.alt_display}), 'aria-label': "Sign In #{if prov.label then 'with ' + prov.label else ''} button", handler: @oAuthSignIn(utils.expandUriTemplate(prov.href, templateParams)),
                    if prov.logo_url
                      img className: cx('OAuthLink-logo', {alt: prov.alt_display}), alt: "#{prov.label} Logo", src: prov.logo_url, role: 'presentation', width: 'auto', height: '100%',
                    span className: 'OAuthLink-label', prov.label
            span className: 'ruled-span ruled-span--empty', style: {paddingTop: '8px'}, ''
          ]

        div className: 'SignInForm-activate-link',
          div className: 'AuthCard--link-text', "Don't have an account? "
          tch tagName: 'a', href: '#', className: 'Button--pointer Auth--informational-link underline', handler: @goToSignup, 'Activate'

        Tooltip(@state.tooltip)

module.exports = SignInForm
